import Appsignal from "@appsignal/javascript"
import { plugin as breadcrumbsNetworkPlugin } from "@appsignal/plugin-breadcrumbs-network"
import { plugin as breadcrumbsConsolePlugin } from "@appsignal/plugin-breadcrumbs-console"

const key = document.head.querySelector("meta[name=appsignal-push-key]").content
const revision = document.head.querySelector("meta[name=revision]").content

const appsignal = new Appsignal({
  key: key,
  revision: revision
})

appsignal.use(breadcrumbsNetworkPlugin)
appsignal.use(breadcrumbsConsolePlugin)

export { appsignal }
